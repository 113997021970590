<template>
  <div id="planning-lv-container">
    <div class="head">
      <div class="titre">
        <h1>
          TOURS DE SERVICE - LIEU DE VIE
          <ButtonClassic
            variant="simple"
            color="primary"
            icon="left"
            @click="$modal.show('modal_info_planning_lv');"
          >
            <template #left-icon>
              <UilInfoCircle />
            </template>
          </ButtonClassic>
        </h1>
        <h2>
          <ButtonClassic
            variant="solid"
            icon="left"
            @click="moveMonth(-1)"
            :disabled="isLoading"
          >
            <template #left-icon>
              <UilAngleLeft />
            </template>
          </ButtonClassic>
          <span v-if="!isLoading && planning?.date_debut && planning?.date_fin">
            Du {{ planning.date_debut?.replaceAll("-", "/") }} au {{ planning.date_fin?.replaceAll("-", "/") }}
          </span>
          <span v-else>
            Chargement en cours...
          </span>
          <ButtonClassic
            variant="solid"
            icon="left"
            @click="moveMonth(1)"
            :disabled="isLoading"
          >
            <template #left-icon>
              <UilAngleRight />
            </template>
          </ButtonClassic>
        </h2>
      </div>
      <ContextMenu
        :bloc-actions-globales="actionsGlobales"
        @click-action="handleAction($event)"
        :disabled="isLoading"
      />
    </div>
    <div class="content">
      <div class="description">
        <p><strong>Chaque collaborateur fait son tour de nettoyage qui consiste à :</strong></p>
        <ul>
          <li>
            Nettoyer (tiroir à marc, réservoir récupérateur d'eau, etc.)
            et remplir les machines à café (eau, grains)
          </li>
          <li>Détartrer les machines (jeudi à 16h) et changer le filtre (1x/mois)</li>
          <li>Vider le lave-vaisselle le matin et à la fin du repas à 14h</li>
          <li>Passer un coup d'éponge dans l'évier et sur les plans de travail</li>
          <li>Remplacer les torchons</li>
        </ul>
      </div>
      <div class="description">
        <p>
          En cas d’absence, il est de la responsabilité du collaborateur
          de s’assurer que quelqu’un d’autre s’occupera de cette tâche.
        </p>
      </div>
      <div
        class="tables"
      >
        <table>
          <thead>
            <tr class="actions">
              <th colspan="3">
                <div>
                  <ButtonCheckbox
                    v-model="showEmptyDays"
                    name="showEmptyDays"
                    label="Afficher les jours non affectés"
                  />
                </div>
              </th>
            </tr>
            <tr>
              <th>Date</th>
              <th class="wide">Collaborateur</th>
              <th>Détartrage</th>
            </tr>
          </thead>
          <tbody      
            v-if="!isLoading && planning?.date_debut && planning?.date_fin"
          >
            <tr
              v-for="day in completeTable"
              v-if="showEmptyDays || day.utilisateur"
              :key="day.utilisateur?.id || day.date.getTime()"
              :class="{
                bold: day?.utilisateur?.is_self,
                gray: !day?.utilisateur
              }"
            >
              <td>{{ dateToTexte(day.date) }}</td>
              <td class="wide">
                {{ day.utilisateur?.nom?.toUpperCase() || '' }} {{ day.utilisateur?.prenom?.capitalize() || '' }}
              </td>
              <td
                :class="{
                  gray: !day.utilisateur || !day.detartrage,
                  green: day.utilisateur && day.detartrage
                }"
              >
              </td>
            </tr>
            <tr
              v-if="!completeTable.some((day) => day.utilisateur) && !showEmptyDays"
            >
              <td colspan="3" class="empty">Aucun collaborateur n’a été affecté pour cette période.</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" class="empty">Chargement...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ModalGenerationPlanningLV
      @success="fetchPlanning"
    />
    <ModalInfoPlanningLV />
  </div>
</template>

<script>
import {
  ButtonCheckbox,
  ButtonClassic,
// InputClassic,
// SelectClassic,
// copyToClipboard,
} from "@lde/core_lde_vue";

import { UilAngleLeft, UilAngleRight, UilInfoCircle, UilSchedule } from "@iconscout/vue-unicons";

import ContextMenu from "../components/ContextMenu.vue";
import IconExcel from "../components/icons/IconExcel.vue";
import ModalGenerationPlanningLV from "../components/modals/ModalGenerationPlanningLV.vue";
import ModalInfoPlanningLV from "../components/modals/ModalInfoPlanningLV.vue";

import Api from "@/modules/axios";
import { mapGetters } from "vuex";
import config from "@/config";


Object.defineProperty(String.prototype, "capitalize", {
  value() {
    // Prendre en compte les string composées (ex: "jean-michel", "anne hélène")
    return this.split(/(?:,| )+/).map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(" ");
  },
  enumerable: false,
});

/**
 * Formulaire pour Antares
 */
export default {
  name: "PlanningLV",
  components: {
    ButtonCheckbox,
    ButtonClassic,
    ContextMenu,
    IconExcel,
    ModalGenerationPlanningLV,
    ModalInfoPlanningLV,
    UilAngleLeft,
    UilAngleRight,
    UilInfoCircle,
    UilSchedule,
  },
  data() {
    return {
      hotlineLink: process.env.VUE_APP_HOTLINE_URL,
      monthToDisplay: {
        "month": new Date().getMonth() + 1,
        "year": new Date().getFullYear(),
      },
      planning: {},
      isLoading: false,
      showEmptyDays: true,
    };
  },
  computed: {
    ...mapGetters(["user", "country"]),
    config() {
      return config;
    },
    isDe() {
      return this.country === "de";
    },
    completeTable() {
      const days = [];
      const startDate = new Date(this.planning?.date_debut.split('-').reverse().join('-'));
      const endDate = new Date(this.planning?.date_fin.split('-').reverse().join('-'));
      const oneDay = 86400000;

      for (let time = startDate.getTime(); time <= endDate.getTime(); time += oneDay) {
        const day = new Date(time);
        const corvee = this.planning?.corvees?.find((c) => c?.date === day.toISOString().slice(0, 10));

        days.push({
          date: day,
          utilisateur: corvee ? corvee.utilisateur : null,
          detartrage: day.getDay() === 4,
        });
      }
      return days;
    },
    actionsGlobales() {
      return [
        {
          title: "Action(s) globale(s)",
          actions: [
            {
              slug: "c_parti_les_zamis",
              label: "Générer",
              icon: UilSchedule,
              disabled: this.isLoading,
            },
          ]
        },
        {
          title: "Action(s) applicable(s) au contenu affiché",
          actions: [
            {
              slug: "export_excel",
              label: "Exporter le tableau",
              icon: IconExcel,
              disabled: this.isLoading || this?.planning?.corvees?.length === 0,
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.fetchPlanning();
  },
  methods: {
    // copyToClipboard,
    handleAction(action) {
      switch (action?.slug) {
        case "c_parti_les_zamis":
          this.$modal.show("modal_generation_planning_lv");
          break;
        case "export_excel":
          window.open(this.getExportUrl(), "_blank");
          break;
        default:
          break;
      }
    },
    fetchPlanning() {
      this.isLoading = true;
      // Si pas de mois on prend le mois actuel
      // C'est stocké au format "MM-YYYY"
      Api().get(
        `/lv/planning/${this.monthToDisplay.month}/${this.monthToDisplay.year}`
      ).then((response) => {
        this.planning = response.data?.planning;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    dateToTexte(date) {
      return `${new Date(date).toLocaleDateString(
        this.isDe ? "de-DE" : "fr-FR",
        {
          // Capitalize weekday
          weekday: "long",
        },
      ).capitalize() } ${ new Date(date).toLocaleDateString(
        this.isDe ? "de-DE" : "fr-FR",
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
      )}`;
    },
    moveMonth(direction) {
      // On ajoute la valeur "direction" au mois (-1 ou 1)
      this.monthToDisplay.month += direction;
      // Si on est en janvier et qu'on veut aller en décembre
      if (this.monthToDisplay.month === 0) {
        this.monthToDisplay.month = 12;
        this.monthToDisplay.year -= 1;
      }
      // Si on est en décembre et qu'on veut aller en janvier
      else if (this.monthToDisplay.month === 13) {
        this.monthToDisplay.month = 1;
        this.monthToDisplay.year += 1;
      }

      this.fetchPlanning();
    },
    getExportUrl() {
      return `${this.config.api.baseUrl}/lv/planning/export/${this.planning.date_debut}`;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_planning_lv.scss";
</style>
