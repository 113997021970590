<template>
  <Modal
    id="modal_info_planning_lv"
    element-to-focus="button"
  >
    <template #title>
      Génération du planning - Informations
    </template>
    <template #subtitle>
      Cet outil permet de générer, d'afficher ou d'exporter au format XLSX le planning.
    </template>
    <template #content>
      <ul>
        <li class="bloc-infos">
          <div class="infos text-regular">
            Options
          </div>
          <p class="explanation text-medium">
            Les options disponibles sont accessibles via le menu contextuel symbolisé par trois points verticaux, en haut à droite.<br />
          </p>
        </li>
        <li class="bloc-infos">
          <div class="infos text-regular">
            <div class="icon">
              <UilSchedule />
            </div>
            <div class="texte">Génération</div>
          </div>
          <p class="explanation text-medium">
            La génération fonctionne ainsi :<br />
            - On récupère les générations précédentes<br />
            - On récupère les congés & télétravail renseignés sur iLucca<br />
            - On récupère les jours fériés<br />
            <br />
            Les utilisateurs sont triés par ordre de priorité (dernier passage), sinon par équipe.<br />
            Si un collaborateur est indisponible à la première date à laquelle il est censé passer, on va prendre le collaborateur suivant dans la liste.<br />
            Dès lors qu'une date convient, le premier collaborateur dans la liste est sélectionné.<br />
            <br />
            Les congés renseignés après la génération ne sont pas pris en compte, ce pourquoi il est préférable de ne générer qu'une courte période, dans un futur proche <i>(Prochain mois le 20, par exemple)</i><br />
            <br />
            <strong>/!\ La génération efface automatiquement les générations précédentes, sans possibilité de revenir en arrière /!\</strong><br />
          </p>
        </li>
        <li class="bloc-infos">
          <div class="infos text-regular positive">
            <div class="icon">
              <IconExcel />
            </div>
            <div class="texte">Export</div>
          </div>
          <p class="explanation text-medium">
            L'export, par défaut, contiendra les deux mois affichés.<br />
          </p>
        </li>
      </ul>
    </template>
    <template #footer>
      <ButtonClassic
        label="J'ai compris"
        variant="solid"
        color="secondary"
        icon="right"
        @click="hide"
      >
          <template #right-icon>
              <UilThumbsUp />
          </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>
  
<script>
import {
  ButtonClassic,
  Modal,
} from "@lde/core_lde_vue";

import { UilSchedule, UilThumbsUp } from "@iconscout/vue-unicons";
import IconExcel from "@/components/icons/IconExcel.vue";

export default {
  name: "ModalInfoPlanningLV",
  components: {
    ButtonClassic,
    IconExcel,
    Modal,
    UilSchedule,
    UilThumbsUp,
  },
  methods: {
    hide() {
      this.$modal.hide("modal_info_planning_lv");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss"as var;
#modal_info_planning_lv {
  .modal-body {
    color: var.$gray-medium;
    padding: 0 var.$space-large;
  }

  .bloc-infos {
    display: flex;
    flex-direction: column;
    color: var.$blue;
    &:not(:last-child) { margin-bottom: var.$space-small; }
    
    .infos {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon  > * { height: var.$font-regular; }
    }
    .explanation { color: var.$gray-dark; }
  }

  .infos > :first-child { margin-right: var.$space-xx-tiny; }

  $colors: (
    "positive": var.$positive,
    "warning": var.$warning,
    "negative": var.$negative,
    "gray": var.$gray-medium,
    "blue": var.$blue,
    "info": var.$info,
  );
  @each $class, $color in $colors {
    .#{$class} { color: $color; }
  }
}

</style>  