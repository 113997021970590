<template>
  <div>
    <Modal
      id="modal_generation_planning_lv"
      class="modal-lv"
      element-to-focus="button"
      @hidden="!$event && hide()"
      @before-open="initDates()"
    >
      <template #title>
        Génération du planning
      </template>
      <template #content>
        <Toast
          title="Attention !"
          variant="warning"
          content="En validant, vous écraserez toute la période sélectionnée."
          hide-close-btn
        />
        <InputClassic
          v-model="dateDebut"
          label="À partir du"
          type="date"
          required
        />
        <InputClassic
          v-model="dateFin"
          label="Jusqu'au"
          type="date"
          required
        />
        <div
          v-if="errorMsg"
          class="error"
        >
          {{ errorMsg }}
        </div>
      </template>
      <template #footer>
        <ButtonClassic
          label="Annuler"
          variant="ghost"
          color="secondary"
          icon="left"
          @click="hide"
        >
          <template #left-icon>
            <UilTimesCircle />
          </template>
        </ButtonClassic>
        <ButtonClassic
          label="Valider"
          variant="solid"
          color="primary"
          icon="left"
          @click="askConfirm()"
        >
          <template #left-icon>
            <UilCheckCircle />
          </template>
        </ButtonClassic>
      </template>
    </Modal>
    <Modal
      id="modal_generation_planning_lv_confirm"
      class="modal-lv"
    >
      <template #title>
        Êtes-vous sûr(e) ?
      </template>
      <template #content>
        <p>
          En confirmant, vous allez générer le planning pour la période du
          <strong>{{ formatDate(dateDebut) }}</strong> au <strong>{{ formatDate(dateFin) }}</strong>, sans possibilité de revenir en arrière
          et/ou de récupérer les données supprimées.
        </p>
      </template>
      <template #footer>
        <ButtonClassic
          label="Annuler"
          variant="ghost"
          color="secondary"
          icon="left"
          @click="$modal.hide('modal_generation_planning_lv_confirm')"
        >
          <template #left-icon>
            <UilTimesCircle />
          </template>
        </ButtonClassic>
        <ButtonClassic
          label="Oui, je suis sûr(e)"
          variant="solid"
          color="primary"
          icon="left"
          @click="confirm"
        >
          <template #left-icon>
            <UilCheckCircle />
          </template>
        </ButtonClassic>
      </template>
    </Modal>
  </div>
</template>
  
<script>
import {
  ButtonClassic,
  InputClassic,
  Modal,
  Toast,
} from "@lde/core_lde_vue";

import { UilCheckCircle, UilTimesCircle } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";

export default {
  name: "ModalGenerationPlanningLV",
  components: {
    ButtonClassic,
    InputClassic,
    Modal,
    Toast,
    UilCheckCircle,
    UilTimesCircle,
  },
  data() {
    return {
      dateDebut: "",
      dateFin: "",
      errorMsg: "",
    };
  },
  emits: ["hidden", "success", ],
  
  methods: {
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    askConfirm() {
      this.$modal.show("modal_generation_planning_lv_confirm");
    },
    confirm() {
      this.$modal.hide("modal_generation_planning_lv_confirm");
      this.errorMsg = "";
      if (!this.dateDebut || !this.dateFin) {
        this.errorMsg = "Veuillez renseigner les dates de début et de fin.";
        return;
      } else if (new Date(this.dateDebut) > new Date(this.dateFin)) {
        this.errorMsg = "La date de début doit être antérieure à la date de fin.";
        return;
      }

      const formData = new FormData();

      formData.append("dateDebut", this.formatDate(this.dateDebut));
      formData.append("dateFin", this.formatDate(this.dateFin));
      Api().post(
        "/lv/planning/generate",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((response) => {
          if (![200, 201].includes(response.status)) {
            this.errorMsg = "Une erreur est survenue lors de la génération du planning.";
            console.error(response);
          } else {
            this.$toast.success({
              title: "Génération effectuée !",
            });
            this.hide();
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.errorMsg = error?.response?.data?.message || "Une erreur est survenue lors de la génération du planning.";
          console.error(error);
        });
    },
    hide() {
      this.errorMsg = "";
      this.$modal.hide("modal_generation_planning_lv_confirm");
      this.$modal.hide("modal_generation_planning_lv");
      this.$emit("hidden", true);
    },
    initDates() {
      // Date de début = 1er du mois suivant
      const dateDebut = new Date();
      dateDebut.setDate(1);
      dateDebut.setMonth(dateDebut.getMonth() + 1);
      this.dateDebut = dateDebut.toISOString().slice(0, 10);

      // Date de fin = dernier jour du mois suivant
      const dateFin = new Date();
      dateFin.setMonth(dateFin.getMonth() + 2);
      dateFin.setDate(0);
      this.dateFin = dateFin.toISOString().slice(0, 10);
    }
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss"as var;

.modal-lv {
  .modal-body {
    display: flex;
    flex-direction: column;
    gap: var.$space-x-tiny;

    > p {
      font-size: var.$font-regular;
    }

    .error {
      font-size: var.$font-regular;
      color: var.$negative;
    }
  }
  
  .modal-footer *:not(:last-child) {
    margin-right: var.$space-x-tiny;
  }
}
</style>  